import React, { useEffect, useState } from 'react';
import { PageLayout } from '../components/PageLayout';

interface zealDocLayoutContainerProps {
    children: React.ReactNode;
}

function ZealDocLayoutContainer(props: zealDocLayoutContainerProps) {
    const [showNotSupportedScreen, setShowNotSupportedScreen] = useState(false);

    /* Detect media like mobile, iPad */
    const detectMedia = () => {
        if (window.innerWidth <= 767.98) {
            setShowNotSupportedScreen(true);
        } else {
            setShowNotSupportedScreen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('load', detectMedia);
        window.addEventListener('resize', detectMedia);
        return () => {
            window.removeEventListener('load', detectMedia);
            window.removeEventListener('resize', detectMedia);
        };
    }, []);

    return (
        <div className="container-xxl p-0 m-0">
            {showNotSupportedScreen ? (
                <div className="device-not-supported-container">
                    {`You are using a device currently unsupported by the ZealDoc AI platform.`}
                </div>
            ) : (
                <PageLayout>{props.children}</PageLayout>
            )}
        </div>
    );
}

export default ZealDocLayoutContainer;
