// Import Header from './Header';
import '../styles/page-layout.scss';
import { ReactComponent as AllLossIcon } from '../images/all-losses-icon.svg';
import { ReactComponent as DashboardIcon } from '../images/dashboard-icon.svg';
import { ReactComponent as GozealZLogo } from '../images/gozeal-z-logo.svg';
import { ReactComponent as LandingIcon } from '../images/landing-icon.svg';
import { ReactComponent as MasterDataIcon } from '../images/master-data.svg';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { ReactComponent as SettingsIcon } from '../images/settings-icon2.svg';
import routes from '../routes/routes.json';

interface typePageLayoutProps {
    children: React.ReactNode;
}

const ContentLayout = (props: typePageLayoutProps) => {
    return (
        <div className="content-layout">
            <div id="navigation-panel">
                <div className="navigation-panel-container">
                    <div className="nav flex-column nav-pills navigation-item-container">
                        <div className="gz-logo">
                            <a className="navbar-brand" href="/">
                                <GozealZLogo fill="#7F7FF0" width={32} height={32} />
                            </a>
                        </div>
                        <div className="navigation-item-wrapper gz-scroll">
                            <NavLink
                                to={routes['dashboard']}
                                className="nav-link gz-nav-btn"
                                end
                            >
                                <DashboardIcon width="26" height="26" />
                                <span>Dashboard</span>
                            </NavLink>
                            <NavLink
                                to={routes['allLoss']}
                                className={'nav-link gz-nav-btn'}
                            >
                                <AllLossIcon width="26" height="26" />
                                <span>Loss Runs</span>
                            </NavLink>
                            <NavLink
                                to={routes['landing']['renamer']}
                                className={'nav-link gz-nav-btn'}
                            >
                                <LandingIcon width="26" height="26" />
                                <span>Landing</span>
                            </NavLink>
                            <NavLink
                                to={routes['master-data']['account']}
                                className="nav-link gz-nav-btn gray-out-btn"
                            >
                                <MasterDataIcon width="26" height="26" />
                                <span>Master Data</span>
                            </NavLink>
                            <NavLink
                                to={routes['setting']['preprocessing']}
                                className={'nav-link gz-nav-btn'}
                            >
                                <SettingsIcon width="26" height="26" />
                                <span>Settings</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-content" id="panel-content">
                {props.children}
            </div>
        </div>
    );
};

export const PageLayout = (props: typePageLayoutProps) => {
    return (
        <ContentLayout>
            {/* <Header/> */}
            {props.children}
        </ContentLayout>
    );
};
