import NavigationTab from '../tab/NavigationTab';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { postprocessingTabItem } from '../tab/TabItems';

function Postprocessing() {
    return (
        <div className="postprocessing-container">
            <NavigationTab
                tab="settings"
                tabItems={postprocessingTabItem}
                variant="secondary"
            />
            <Outlet />
        </div>
    );
}

export default Postprocessing;
