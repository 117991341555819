import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { ReactComponent as GirlYogaIllustration } from './images/girl-yoga-illustration.svg';
import Header from './components/Header';
import React from 'react';
import ZealDocRoutes from './routes/ZealDocRoutes';

const App = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <BrowserRouter>
                    <ZealDocRoutes />
                </BrowserRouter>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Header />
                <div className="text-center">
                    <GirlYogaIllustration width={280} height={280} />
                    <div>Please sign-in with your Microsoft login</div>
                </div>
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
