import route from '../routes/routes.json';

export interface tabItemType {
    url: string;
    label: string;
    order: number;
}

export const masterDataTabItem: tabItemType[] = [
    {
        url: route['master-data']['account'],
        label: 'Account',
        order: 1,
    },
    {
        url: route['master-data']['carrier'],
        label: 'Carrier',
        order: 2,
    },
    {
        url: route['master-data']['lob'],
        label: 'Line Of Business',
        order: 3,
    },
    {
        url: route['master-data']['lkpInsured'],
        label: 'Lookup Insured',
        order: 4,
    },
    {
        url: route['master-data']['policySchedule'],
        label: 'Policy Schedule',
        order: 5,
    },
];

export const settingsTabItem: tabItemType[] = [
    {
        url: route['setting']['preprocessing'],
        label: 'Pre-Processing',
        order: 1,
    },
    {
        url: route['setting']['postprocessing'],
        label: 'Post-Processing',
        order: 2,
    },
];

export const landingTabItem: tabItemType[] = [
    {
        url: route['landing']['renamer'],
        label: 'Renamer',
        order: 1,
    },
    {
        url: route['landing']['fileUpload'],
        label: 'File Upload',
        order: 2,
    },
];

export const postprocessingTabItem: tabItemType[] = [
    {
        url: route['setting']['pdfTransform'],
        label: 'PDF Transform',
        order: 1,
    },
    {
        url: route['setting']['excelTransform'],
        label: 'Excel Transform',
        order: 2,
    },
];
