import '../styles/primary-navigation-tab.scss';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
// Import {GozealGatewayContext} from '../../gozeal-gateway/context-provider/GozealGatewayContextProvider';
// Import {ProjectGlobalContext} from '../context-provider/projectGlobalContextProvider';
// Import UserProfileAndTimeBar from '../../components/UserProfileAndTimeBar';
import _ from 'lodash';
import classnames from 'classnames';
import { tabItemType } from './TabItems';

interface navigationTabProps {
    tab: 'dashboard' | 'allLosses' | 'landing' | 'masterData' | 'settings';
    tabItems: tabItemType[];
    variant?: 'primary' | 'secondary';
}

function NavigationTab(props: navigationTabProps) {
    //   Const {
    //     UserPermission: {state,
    //       Actions: {setAboutMeData}},
    //   } = useContext(ProjectGlobalContext);
    //   Const setAboutMeDataRef = useRef(setAboutMeData);
    //   Const gozealMainContext = useContext(GozealGatewayContext);
    const currentLocation = useLocation();

    //   UseEffect(() => {
    //     SetAboutMeDataRef.current(gozealMainContext.state);
    //   }, [
    //     GozealMainContext.state,
    //   ]);

    //   Const baseUrl = route['master-data']['index'];

    /* Get classname */
    const getClassName = (order: number, url: string) => {
        switch (order) {
            case 1:
                return `nav-link-order-1 ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            case 2:
                return `nav-link-order-2 gray-out-btn ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            case 3:
                return `nav-link-order-3 gray-out-btn ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            case 4:
                return `nav-link-order-4 gray-out-btn ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            default:
                return `nav-link-order-1 ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
        }
    };

    const tabItems = _.cloneDeep(props.tabItems).sort((a, b) => {
        return a.order - b.order;
    });

    return (
        <div className="zd-navigation-tab-container">
            <div
                className={classnames('nav nav-tabs', 'zd-navigation-tab', props.variant)}
            >
                <div className="zd-navigation-items">
                    {tabItems.map((navItem, index) => {
                        return (
                            <NavLink
                                to={`${navItem.url}`}
                                className={getClassName(navItem.order, navItem.url)}
                                end={index === 0}
                                key={`${navItem.url}`}
                            >
                                {navItem.label}
                            </NavLink>
                        );
                    })}
                </div>
                {/* <UserProfileAndTimeBar userPermission={state}/> */}
            </div>
            <div className="zd-navigation-content gz-scroll">
                <Outlet />
            </div>
        </div>
    );
}

export default NavigationTab;
